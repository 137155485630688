import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ParcelsService} from '../../../services/parcels/parcels.service';
import {StatusModel} from '../../../models/status.model';
import {ToastsService} from '../../../services/toasts.service';
import {environment} from '../../../../environments/environment';
import {ScanningService} from '../../../services/scanning/scanning.service';
import {ParcelService} from '../../../services/parcel/parcel.service';

@Component({
  selector: 'app-parcel-from-number',
  templateUrl: './parcel-from-number.component.html',
  styleUrls: ['./parcel-from-number.component.scss']
})
export class ParcelFromNumberComponent implements OnInit, OnDestroy {
  @ViewChild('barcodeInput') barcodeInput: ElementRef;
  clients = [];
  couriers = [
    {
      name: 'Sameday Romania',
      code: 'sameday_romania'
    },
    {
      name: 'DPD Romania',
      code: 'dpd_romania'
    }
  ];
  courier_code = 'sameday_romania';
  internalNumber = null;

  findParcelDataColumns: string[] = [
    'weight', 'weight_value', 'height', 'width', 'length', 'volumetric_weight', 'out_number', 'internal_number',
  ];

  apiurl = null;
  id = null;
  statuses = [];
  idClient = null;
  client = null;

  handwriting = false;

  scanningLocked = false;

  public lastChars = '';
  public lastKeyCode = null;
  public clearLastCharsInterval = null;

  statusTypes = [];
  statusModel = {
    id_client: null,
    barcode: null,
    id_status_type: 55,
    when: '',
    where: 'TYCHY',
  };
  parcelModel = null;
  operatedParcelId = null;

  constructor(
      private parcelService: ParcelService,
      private parcelsService: ParcelsService,
      private toastsService: ToastsService,
      private scanningService: ScanningService
  ) {
    this.apiurl = environment.api;
  }

  ngOnInit() {
    this.keyReading();
    this.clearLastChars();
    this.getStatusTypes();
    this.setTime();
  }

  switchHandWriting() {
    this.handwriting = !this.handwriting;
  }

  ngOnDestroy() {
    document.removeEventListener('keyup', this.listenerAction, false);
  }

  keyReading() {
    document.addEventListener('keyup', this.listenerAction, false);
  }


  eanChecker(tempChars) {
    if (tempChars.length === 8) {
      this.toastsService.showMessage('Zeskanowano nieprawidłowy kod - wykryto EAN8', 'w', 5000);
      return false;
    }
    if (tempChars.length === 13) {
      this.toastsService.showMessage('Zeskanowano nieprawidłowy kod - wykryto EAN13', 'w', 5000);
      return false;
    }
    if (tempChars.length === 14) {
      this.toastsService.showMessage('Zeskanowano nieprawidłowy kod - wykryto EAN14', 'w', 5000);
      return false;
    }
    if (tempChars.length > 28 && tempChars.length !== 35 && tempChars.length !== 31) {
      this.toastsService.showMessage('Zbyt szybko skanujesz przesyłki', 'w', 5000);
      return false;
    }

    return true;
  }

  listenerAction = (e) => {
    this.clearLastChars();
    if ((e.ctrlKey || e.metaKey)) {
      return;
    }

    this.lastKeyCode = e.keyCode;

    if ((e.keyCode >= 48 && e.keyCode <= 57 && this.lastKeyCode !== 16)
      || e.keyCode === 189 || (e.keyCode > 40 && e.keyCode < 91)) {
      if (e.keyCode === 189) {
        this.lastChars += '_';
      } else {
        this.lastChars += String.fromCharCode(e.keyCode) + '';
      }
    }

    this.lastKeyCode = e.keyCode;
  }

  getParcelStatuses(internalNumber) {
    setTimeout(
      () => {
        this.parcelsService.getParcelStatuses(internalNumber).subscribe(
          (response: any) => {
            response = response.sort((a, b) => {
              return (a.when < b.when) ? 1 : ((a.when > b.when) ? -1 : 0);
            });
            this.statuses = response;
          }
        );
      }, 2500
    );
  }

  setTime() {
    const date = new Date();
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = String(date.getFullYear());
    const hour = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
    this.statusModel.when = `${year}-${month}-${day}T${hour}:${minutes}:${seconds}`;
  }

  updateStatus() {
    if (this.scanningLocked) {
      return;
    }
    if (this.lockNextScanRequest) {
      this.lockNextScanRequest = false;
      return;
    }

    this.parcelModel = null;
    this.statuses = [];
    this.handwriting = false;
    this.scanningService.scanParcel(this.statusModel).subscribe(
      (response: any) => {
        if (response.status === 204) {
          return;
        }

        this.operatedParcelId = response.body.data.parcel_id;

        let showShortSuccessMessage = true;
        for (const message of response.body.messages) {
          this.toastsService.showMessage(message.text, message.type, 15000);
          showShortSuccessMessage = false;
        }

        if (showShortSuccessMessage) {
          this.toastsService.showBigSuccessMessage();
        }

        this.findParcelData(response.body.data.parcel_id, false, response.body.data.re_pasting_required);
        this.findParcelStatusesHistory(response.body.data.parcel_id);
      }, error => {
        console.log(error.status);

        switch (error.status) {
          case 410:
            this.toastsService.showDeletedParcelMessage();
            return;
        }

        console.log(error.error);
        for (const err of error.error){
          this.toastsService.showMessage(err, 'e', 5000);
        }
      }
    );
    this.setTime();
  }

  findParcelStatusesHistory(parcelId) {
    this.parcelService.findByHistoryIdentifier(parcelId).subscribe(
      (response: any) => {
        this.statuses = response.sort((a, b) => {
          return (a.when < b.when) ? 1 : ((a.when > b.when) ? -1 : 0);
        })
      }
    );
  }

  findParcelData(parcelId, clearInfo = false, openRePasting = false) {
    this.parcelService.findByIdentifier(parcelId, this.findParcelDataColumns).subscribe(
      (response: any) => {
        this.parcelModel = response;

        if (openRePasting) {
          if ('-' == this.parcelModel.out_number) {
              this.toastsService.showMessage('Przesyłka nie posiada numeru wyjściowego', 'e', 20000)
          }
          else {
            this.openLabelToPrint(this.parcelModel.internal_number);
          }
        }

        if (clearInfo) {
          setTimeout(
            () => {
              this.statusModel.barcode = '';
              this.parcelModel = null;
              this.statuses = [];
              this.lockNextScanRequest = true;
              this.operatedParcelId = null;
            }, 1500
          );
        }
      }
    );
  }

  clearLastChars() {
    if (null !== this.clearLastCharsInterval) {
      clearInterval(this.clearLastCharsInterval);
      this.clearLastCharsInterval = null;
    }
    this.clearLastCharsInterval = setInterval(
      () => {
        if (this.handwriting) {
          return;
        }
        this.checkNumbers();
        this.lastChars = '';
      }, 1000
    );
  }

  checkNumbers() {
    if (this.scanningLocked || this.handwriting) {
      return;
    }

    if (this.lastChars.length === 0 || !this.eanChecker(this.lastChars)) {
      return;
    }

    this.statusModel.barcode = this.lastChars;

    this.updateStatus();
  }

  async onStatus(idStatus) {
    const promised = await this.toastsService.showConfirmModal();
    if (!promised) {
      return;
    }
    this.parcelsService.deleteStatus(idStatus).subscribe(
      (s: StatusModel) => {
        this.toastsService.showMessage('Status usunięty', 's');
        this.getParcelStatuses(this.internalNumber);
      }
    );
  }

  getStatusTypes() {
    this.parcelsService.getStatusTypes(1).subscribe(
      (response: any) => {
        this.statusTypes = response;
        if (response.length > 0) {
          this.statusModel.id_status_type = response[0].id;
        }
      }
    );
  }

  openLabelToPrint(outNumber) {
    this.parcelsService.getPublicFileToken().subscribe(
      (response: any) => {
        window.open(this.apiurl + 'public/label/' + outNumber + '?_public_token=' + response.public_token);
      }
    );
  }

  async showConfirmDimensionsModal(form, message){
    return await this.toastsService.showConfirmDimensionsModal(form, message);
  }

  lockNextScanRequest = false;
  updateDimensions() {
    const form = {
      id: this.operatedParcelId,
      weight: this.parcelModel.weight,
      length: this.parcelModel.length,
      height: this.parcelModel.height,
      width: this.parcelModel.width,
      context: 'UPDATE_PARCEL_DIMENSIONS_DURING_SCAN',
    };

    this.parcelService.checkDimensions({
      weight: this.parcelModel.weight,
      length: this.parcelModel.length,
      height: this.parcelModel.height,
      width: this.parcelModel.width,
    }).subscribe(
      (checkResponse: any) => {
        this.updateDimensionsRequest(form);
      },
      e => {
        if (e.status === 406) {
          this.showConfirmDimensionsModal(form, e.error[0]).then(confirmed => {
              if (confirmed) {
                this.updateDimensionsRequest(form);
              } else {
                this.toastsService.showMessage('Zmiany nie zostały zapisane', 'w', 3000)
              }
            },
          );
        }
      }
    );
  }

  updateDimensionsRequest(form) {
    this.parcelService.updateDimensions(form).subscribe(
      (response: any) => {
        this.toastsService.showMessage('Wymiary przesyłki zostały zaktualizowane!', 's');
        this.scanningLocked = false;
        this.findParcelData(this.operatedParcelId, true);
        this.barcodeInput.nativeElement.focus();
        this.lastChars = '';
      },
      e => {
        console.log(form);
        this.toastsService.showMessage(
          e.status === 422
            ? e.error.message
            : 'Wystąpił błąd podczas aktualizacji wymiarów',
          'e'
        );
      }
    );
  }
}
